<div *ngIf="driver">
  <div>
    <label>Nome: </label> {{driver.name}}
  </div>
  <div>
    <label>Matrícula: </label> {{driver.identifier}}
  </div>
  <div>
    <label>Frotas: </label>
    <span class="example-list-section">
      <li *ngFor="let fleet of driver.fleets">
        {{fleet.name}}
      </li>
    </span>
  </div>
  
  <button mat-raised-button color="primary" (click)='edit()'>Editar</button>
  <button mat-raised-button color="warn" (click)='inactivate()'>Apagar</button>


  <hr />
</div>