<div *ngIf="equipment">
    <div>
      <label>TAG: </label> {{equipment.name}}
    </div>
    <!-- <div>
      <label>Age: </label> {{customer.id}}
    </div> -->
    <div>
      <label>Frota: </label> {{equipment.fleetName}}
    </div>
   
    <!-- <span class="button is-small btn-primary" *ngIf='customer.active' (click)='updateActive(false)'>Inactive</span> -->
   
    <!-- <span class="button is-small btn-primary" *ngIf='!customer.active' (click)='updateActive(true)'>Active</span> -->
       
  <button mat-raised-button color="primary" (click)='edit()'>Editar</button>
  <button mat-raised-button color="warn" (click)='inactivate()'>Apagar</button>
   
    <hr />
  </div>