<body>
    <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field class="example-full-width">
            <mat-label>Equipamento</mat-label>
            <mat-select [(value)]="equipment" (selectionChange)="onDateChange(dateStart.value, dateEnd.value)">
                <mat-option [value]="'all'">Todos</mat-option>
                <mat-option *ngFor="let equipment of equipments" [value]="equipment.id">
                    {{equipment.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>Frota</mat-label>
            <mat-select [(value)]="fleet" (selectionChange)="onDateChange(dateStart.value, dateEnd.value)">
                <mat-option [value]="'all'">Todas</mat-option>
                <mat-option *ngFor="let fleet of fleets" [value]="fleet.id">
                    {{fleet.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>Início</mat-label>
            <input matInput #dateStart [owlDateTimeTrigger]="dt12" [owlDateTime]="dt12" [selectMode]="'rangeFrom'"
                [(ngModel)]="selectedMoments" (dateTimeInput)="onDateChange(dateStart.value, dateEnd.value)">
            <owl-date-time #dt12></owl-date-time>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>Fim</mat-label>
            <input matInput #dateEnd [owlDateTimeTrigger]="dt13" [owlDateTime]="dt13" [selectMode]="'rangeTo'"
                [(ngModel)]="selectedMoments" (dateTimeInput)="onDateChange(dateStart.value, dateEnd.value)">
            <owl-date-time #dt13></owl-date-time>
        </mat-form-field>

        <button mat-raised-button color="basic" type="submit" (click)="clear()" class="btn-block">Limpar</button>
    </div>

    <mat-form-field class="example-fill-width">
        <mat-label>Filtro</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
    </mat-form-field>

    <div class="mat-elevation-z8" style="margin-bottom: 50px;" #TABLE>
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="date_start">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Início </th>
                <td mat-cell *matCellDef="let row"> {{row.date_start.toDate() | date:'dd/MM/yyyy - HH:mm:ss'}} </td>
            </ng-container>

            <ng-container matColumnDef="date_end">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fim </th>
                <td mat-cell *matCellDef="let row"> {{getDateEnd(row.date_end).toDate() | date:'dd/MM/yyyy - HH:mm:ss'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Duração </th>
                <td mat-cell *matCellDef="let row"> {{getDuration(row.date_start, row.date_end)}} </td>
            </ng-container>

            <ng-container matColumnDef="geocerca">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Geocerca </th>
                <td mat-cell *matCellDef="let row"> {{row.geocerca}} </td>
            </ng-container>

            <ng-container matColumnDef="kms">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Kilômetros </th>
                <td mat-cell *matCellDef="let row"> {{row.kms}} </td>
            </ng-container>

            <ng-container matColumnDef="liters">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Litros </th>
                <td mat-cell *matCellDef="let row"> {{row.liters}} </td>
            </ng-container>

            <ng-container matColumnDef="horimetro">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Horimetro </th>
                <td mat-cell *matCellDef="let row"> {{row.horimetro}} </td>
            </ng-container>

            <ng-container matColumnDef="fleet">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Frota </th>
                <td mat-cell *matCellDef="let row"> {{row.fleet}} </td>
            </ng-container>

            <ng-container matColumnDef="equipment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Equipamento </th>
                <td mat-cell *matCellDef="let row"> {{row.equipment}} </td>
            </ng-container>

            <ng-container matColumnDef="operator">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Operador </th>
                <td mat-cell *matCellDef="let row"> {{row.operator}}</td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>
        
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 1000, 10000]"></mat-paginator>
        <button mat-raised-button (click)="exportAsExcel()">Exportar</button>
    </div>
</body>