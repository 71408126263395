<div *ngIf="fleet">
    <div>
      <label>TAG: </label> {{fleet.name}}
    </div>
    <div>
      <label>Limite de velocidade: </label> {{fleet.speedLimit}}
    </div>
    <!-- <div>
      <label>Age: </label> {{customer.id}}
    </div> -->
    <div>
      <label>Classe: </label> {{fleet.fleetClass}}
    </div>
   
    <button mat-raised-button color="primary" (click)='edit()'>Editar</button>
    <button mat-raised-button color="warn" (click)='inactivate()'>Apagar</button>
    
    <hr />
  </div>